@import "./base.scss";
@import '../bootstrap/variable';


.sidebar-wraper{
    width: $sidebar-width;
    position: fixed;
    left: 0px;
    top: 0px;
}

.mainpage{
    flex: 0 0 auto;
    background-color: $secondary-color;
    height: 100%;
    position: fixed;
    left: $mainpage-left;
    width: 100%;
    transition: all 1s; 
    overflow-y: auto;
    h3{
        margin-left: 30px;
        margin-bottom: 15px;
        font-family: 'Poppins';
        font-size: 40px;
    }
    .breadcrumb{
        margin-left: 30px;
        margin-top: 20px;
        font-family:'Open Sans';
    }
}

.mainpage__transform{
    flex: 0 0 auto;
    background-color: $secondary-color;
    height: 100%;
    position: fixed;
    left: 20%;
    // width: 85%;
    transition: all 1s;
    overflow-y: auto;
    h3{
        margin-left: 30px;
        margin-bottom: 15px;
        font-family: 'Poppins';
        font-size: 40px;
    }
    .breadcrumb{
        margin-left: 30px;
        margin-top: 20px;
        font-family:'Open Sans';
    }
}

.content {
    background-color: white;
    padding: 2rem;
    margin-left: 22px;      
}

.content-no-color {
    padding: 2rem;
    margin-left: 22px;      
}

.extra-content {
    padding-left:33px;
}

.backButton {
    max-width: fit-content;
}

.alignSelf {
    align-self: center;
}

.form-wraper{
    //background-color: white;
    padding: 2rem;
    margin-left: 40px;      
}

form{
    h5{
        font-family: 'Poppins';
        font-size: 25px;
    }
    .form-group{
        margin-bottom: 15px;
        .form-label{
          font-family:'Poppins';
          text-transform: uppercase;
          font-size: 13px;
        }
     }
}

.btn-primary{
    background-color: $primary-color;
}
.btn-secondary{
    background-color: white;
    color:$primary-color;
    border-color:$primary-color;
    // width: 100px;
}

.btn-white {
    background-color: white;
    color:$primary-color;
    border-color:$primary-color;
    border:none;
}
// .background-setup {
//     background-image: url('../../components/Common/icons/dashboard.jpg');
//     //background-repeat: no-repeat, repeat;
//     height: 100vh;
//     background-size: contain;
//     //opacity: 0.6;
// }

.background-setup {
    background: url('../../components/Common/icons/dashboard.jpg') rgba(112, 112, 112, 0.4);
    //background-repeat: no-repeat, repeat;
    height: 100vh;
    background-size: contain;
    //background-size: cover;
    //opacity: 0.6;
    background-blend-mode: overlay;
    
}
.noPadding {
    padding: 0px;
}
.noMargin {
    margin: 0px;
}
.mobile-sidebar {
    display:none !important;
    transition: all 1s;
} 

.sidebar-content {
    position: relative;
    top: 10%;
}

.flex-column.active-sidebar {
    width: 20% !important;
}

// showing sidebar icon for mobile design's
.set-absolute {
    position: absolute;
    z-index: 100;
}

.language-setup {
    position: relative;
    display: flex;
    justify-content: flex-end;
}

// TODO
.sidebar-scroll {
    overflow: scroll;
    overflow-x: hidden;
    display: block !important;
}

.switch-lable{
    margin: 10px 10px;
}

.switch-lable-off {
    margin: 0px 10px 0px 0px;   
}
.floorImage{
    width: 90%;
}
.zoom-button{
    // width: 4%;
}
.floor-button{
    text-align: end;
}
.tooltip-floor-hotspot{
    .tooltip-inner{
        background: transparent;
    }
}
